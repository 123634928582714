:root {
    --cc-primary-color: theme("colors.primary");
    --cc-primary-color: theme("colors.primary");
    --cc-text-color: theme("colors.gray.100");
    --cc-text-color-headline: theme("colors.gray.100");
    --cc-modal-box-background: theme(
        "colors.gray.900"
    );
    --cc-border-radius: theme("borderRadius.md");
    --cc-border-color: theme("colors.gray.800");
    --cc-switch-bg-inactive: theme("colors.gray.700");
    --cc-background: rgba(
        21,
        34,
        41,
        0.85
    );
    --cc-btn-primary-hover-bg: #a81e14;
    --cc-btn-secondary-hover-bg: theme("colors.gray.800");
    --cc-btn-default-hover-bg: theme("colors.gray.800");
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";

.consent-wrapper {
    @media (max-width: theme('screens.md')) {
        left: 50% !important;
        top: 70px !important;
        transform: translateX(-50%) !important;
        bottom: 70px !important;
    }

    .Headline{
        @apply font-bold uppercase font-industry text-white text-26;
    }
}

.GdprConsentBrick > button {
    @apply text-gray-100 origin-center transition-transform duration-300 ease-out hover:scale-105;
}

.gdpr {
    &-element {
        @apply w-full h-full;
    }

    &-wrap {
        @apply w-full h-full;
    }

    &-text {
        @apply w-full h-full justify-center flex-col items-center text-center bg-gray-900 lg:hover:bg-gray-900 lg:border lg:border-primary/0 lg:hover:border-primary/100 lg:transition-colors lg:duration-700 rounded-md px-6 md:px-8 lg:px-10 pt-8 md:pt-10 pb-10 md:pb-12;
        display: flex !important;

        .title {
            @apply text-24 lg:text-32 font-industry font-black text-white;
        }

        .description {
            @apply py-4 md:py-6 lg:py-8;
        }

        .button {
            @apply inline-flex items-center justify-start uppercase font-industry font-black rounded-sm bg-primary border-2 border-primary text-white px-2 py-3;
        }
    }
}