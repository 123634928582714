.checkbox-dark{
    @apply appearance-none shrink-0 bg-transparent m-0 relative w-6 h-6 bg-gray-700 border border-gray-600;

    &:checked::after{
        content: "";
        
        background-image: url('../assets/icons/check-light.svg');
        background-size: 90%;

        @apply absolute left-0 top-0 w-full h-full bg-center bg-no-repeat;
    }
}

.checkbox-light{
    @apply appearance-none shrink-0 bg-transparent m-0 relative w-6 h-6 bg-white border border-gray-100;

    &:checked::after{
        content: "";

        background-image: url('../assets/icons/check-primary.svg');
        background-size: 90%;

        @apply absolute left-0 top-0 w-full h-full bg-center bg-no-repeat;
    }
}