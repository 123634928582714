.JobList {
    .job-content {
        strong {
            @apply font-industry font-bold text-21 lg:text-26;
        }

        li {
            @apply flex items-baseline gap-x-2 mb-4;

            &::before {
                content: "";

                background-image: url("../assets/icons/check-primary.svg");
                background-size: 90%;

                @apply block w-4 h-3 shrink-0 bg-center bg-no-repeat;
            }
        }

        ul {
            @apply mb-8 md:mb-10 lg:mb-12 xl:mb-14;
        }

        span, 
        strong *{
            @apply contents;
        }
    }
}
