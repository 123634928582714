@layer utilities {
    .hover-underline {
        @apply relative block w-fit;

        &::before {
            content: "";

            @apply absolute left-0 bottom-0 right-0 h-0.5 bg-current transition-transform duration-300 ease-in-out origin-right scale-x-0;
        }

        &:hover::before {
            @apply scale-x-100 origin-left;
        }
    }

    .animation-zoom-out {
        animation: zoom-out 1000ms ease-out both;
        @apply origin-center;
    }

    @keyframes zoom-out {
        0% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    .animation-fade-in {
        animation: fade-in 500ms ease-out both;
    }

    @keyframes fade-in {
        0% {
            opacity: 0.01;
            transform: translateY(50%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animation-fade-in-absolute {
        animation: fade-in-absolute 500ms ease-out both;
    }

    @keyframes fade-in-absolute {
        0% {
            opacity: 0.01;
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @for $i from 1 through 10 {
        .animation-delay-#{$i * 100} {
            animation-delay: #{100 * $i}ms;
        }

        .animation-duration-#{$i * 100} {
            animation-duration: #{100 * $i}ms;
        }
    }
}
