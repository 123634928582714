.NewsTeaser {
    @media (max-width: theme("screens.md")) {
        .article:not(:nth-of-type(1)) {
            .teaser,
            .link,
            .image {
                @apply hidden;
            }

            .headline {
                @apply mb-0 text-21;
            }

            @apply border-t border-gray-100 pt-4 pb-5;
        }
    }
}
