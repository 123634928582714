// Roboto
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/roboto/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/roboto/Roboto-Bold.woff2") format("woff2");
}

// Industry
@font-face {
    font-family: "Industry";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/industry/Industry-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Industry";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/industry/Industry-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Industry";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/industry/Industry-Black.woff2") format("woff2");
}

// Noto Sans Japanese
@font-face {
    font-family: "Noto Sans Japanese";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-japanese/NotoSansJP-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Japanese";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-japanese/NotoSansJP-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Japanese";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-japanese/NotoSansJP-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Japanese";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-japanese/NotoSansJP-Black.woff2") format("woff2");
}

// Noto Sans Simplified Chinese
@font-face {
    font-family: "Noto Sans Simplified Chinese";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-simplified-chinese/NotoSansSC-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Simplified Chinese";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-simplified-chinese/NotoSansSC-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Simplified Chinese";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-simplified-chinese/NotoSansSC-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Noto Sans Simplified Chinese";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/noto-sans-simplified-chinese/NotoSansSC-Black.woff2") format("woff2");
}