html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    
    @media (min-width: theme("screens.xl")) {
        scroll-padding-top: 170px;
    }
}

body {
    @apply font-helvetica font-regular text-21 text-gray-800 overflow-x-hidden bg-white;
}

[x-cloak] {
    @apply hidden;
}
html.matomoHeatmap {
    [class*="h-screen"] {
        min-height: 0;
        max-height: none;

        @media (min-width: theme("screens.xs")) {
            height: 480px;
        }
        @media (min-width: theme("screens.sm")) {
            height: 768px;
        }
        @media (min-width: theme("screens.md")) {
            height: 1024px;
        }
        @media (min-width: theme("screens.xxl")) {
            height: 1080px;
        }
    }
}