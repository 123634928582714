@layer utilities {
    .container-fluid,
    .container-narrow,
    .container-medium,
    .container-small {
        @apply px-4 sm:px-5 lg:px-9 xl:px-10;
    }

    .container-narrow{
        @apply mx-auto max-w-4xl;
    }

    .container-small{
        @apply mx-auto max-w-6xl;
    }

    .container-medium{
        @apply mx-auto;
        max-width: 1660px;
    }

    .container-1920{
        @apply mx-auto;
        max-width: 1920px;
    }
}
