.Footer {
    &.business-theme-vegetationManagement {
        background: #546751 !important;

        &::after {
            content: "";

            @apply absolute left-0 top-0 bg-black w-full h-full -z-10 opacity-95;

            background: linear-gradient(to bottom, #171e1599, #141b13);
        }
    }

    &.business-theme-crawlerCarriers {
        background: #8b7364 !important;

        &::after {
            content: "";

            @apply absolute left-0 top-0 bg-black w-full h-full -z-10 opacity-90;

            background: linear-gradient(to bottom, #0000009e, #000000f2);
        }
    }

    .info {
        a {
            @apply text-gray-100 hover:text-primary transition-colors duration-300 ease-in-out;
        }
    }
}
