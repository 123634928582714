.FullsizeMediaText{
    .prinoth-connect{
        .circle{
            @apply absolute left-0 top-0 w-full h-full rounded-full origin-center border-primary border-8;

            
            &:nth-of-type(1){
                animation: grow-1 3s ease-in-out infinite both;
            }
            &:nth-of-type(2){
                animation: grow-2 3s ease-out infinite both;
            }
        }

        @keyframes grow-1 {
            0% {
                transform: scale(0);
                opacity: 1;
                filter: blur(5px);
            }
            80%{
                opacity: 1;
                filter: blur(10px);
            }
            100% {
                transform: scale(1);
                opacity: 0;
                filter: blur(20px);

            }
        }

        @keyframes grow-2 {
            0% {
                transform: scale(0);
                opacity: 1;
                filter: blur(5px);
            }
            80%{
                opacity: 1;
                filter: blur(10px);
            }
            100% {
                transform: scale(0.5);
                opacity: 0;
                filter: blur(20px);
            }
        }
    }

    .our-values{
        .circle{
            animation: rotate 20s linear infinite both;
        }

        @keyframes rotate {
            from{
                transform: rotate(0);
            }
            to{
                transform: rotate(360deg);
            }
        }

        .dot::after{
            content: "";
            @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full border-2 border-primary rounded-full opacity-0 transition-opacity duration-700 ease-in-out;

            width: calc(100% + 15px);
            height: calc(100% + 15px);
        }

        .dot.active::after{
            @apply opacity-100;
        }
    }
}