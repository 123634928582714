.swiper-pagination-bullets{
    @apply flex items-center;

    .swiper-pagination-bullet{
        width: 14px;
        height: 6px;
        border-radius: 0;
        opacity: 1;

        @apply bg-gray-100 cursor-pointer transition-colors duration-500 ease-in-out hover:bg-primary;
    }

    .swiper-pagination-bullet-active{
        @apply bg-primary;
    }

    &.dark{
        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active){
            @apply bg-gray-600  
        }
    }
}

.navigation-button{
    @apply cursor-pointer transition-all duration-300 ease-in-out origin-center;

    transition-property: background-color, transform;

    &.swiper-button-disabled{
        @apply bg-gray-100 cursor-default;
    }

    &.dark.swiper-button-disabled{
        @apply bg-gray-600;
    }

    &:not(.swiper-button-disabled):hover{
        @apply hover:scale-95;
    }
}