.range-slider {
    input[type="range"]::-webkit-slider-thumb {
        @apply w-6 h-6 appearance-none pointer-events-auto;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
