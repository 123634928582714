.VerticalScrollItems {
    .item {
        @apply py-0 relative transition-all duration-1000 ease-in-out;

        .content{
            @apply transition-transform -translate-y-1 duration-1000 ease-in-out;

            .headline {
                @apply transition-all duration-1000 ease-in-out opacity-50;
            }
    
            .text {
                @apply transition duration-1000 ease-in-out;
            }
        }

        .image {
            @apply transition origin-center duration-1000 ease-in-out;
        }

        &::after,
        &::before {
            content: "";

            @apply absolute left-0 right-0 bg-gray-600 h-px;
        }

        &::before {
            @apply -top-px;
        }

        &::after {
            @apply bottom-0;
        }

        &.highlighted {
            @apply py-28 z-40;

            &::after,
            &::before {
                content: "";

                @apply bg-primary;
            }

            .content {
                @apply -translate-y-2 md:-translate-y-4 lg:-translate-y-6 xl:-translate-y-10;
            }

            .headline {
                @apply text-26 md:text-32 lg:text-48 opacity-100;
            }

            .text {
                @apply opacity-100 -translate-y-2;
            }

            .image {
                @apply block w-16 md:w-24 lg:w-32 xl:w-40 opacity-100 scale-100;
            }
        }
    }
}
