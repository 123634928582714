@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base";
@import "scrollbar";
@import "admin";
@import "wysiwyg";
@import "plyr";
@import "swiper";
@import "import-fonts";
@import "container";
@import "buttons";
@import "checkbox";
@import "form";
@import "components";
@import "effects";
@import "range-slider";
@import "gdpr-consent";
@import "business-unit-theme";
@import "link-fill";
