.Header {
    .active::after {
        content: "";

        @apply absolute left-0 right-0 -bottom-1 h-0.5 bg-primary;
    }

    @media (max-width: theme("screens.xl")) {
        .product-menu .menu-container{
            left: 0 !important;
            width: 100% !important;
        }
    }
}
