.business-unit-background{
    &.business-theme-snowGroomers{
        @apply bg-gray-600;
    }

    &.business-theme-vegetationManagement{
        @apply bg-businessUnits-vegetationManagement;
    }

    &.business-theme-crawlerCarriers{
        @apply bg-businessUnits-crawlerCarriers;
    }
}